// TODO: Fix the link decorators from google analytics auto adding query params to links. 

@if($usingFontAwesome){
	%icon-before:before {
		@extend %font-awesome-pro !optional;
		margin-right: $margin_2;
		width: 1.28571429em;
		text-align: center;
		text-indent:0;
	}
	%icon-after:after {
		@extend %font-awesome-pro !optional;
		margin-left: $margin_2;
		width: 1.28571429em;
		text-align: center;
		text-indent:0;
	}



	%link-icons{

		// Content Types show Icons in Front
		&[href*=".pdf"]:before,
		&[href*=".doc"]:before,
		&[href*=".docx"]:before,
		&[href*=".txt"]:before,
		&[href*=".zip"]:before,
		&[href*=".ppt"]:before,
		&[href*=".pptx"]:before,
		&[href*=".csv"]:before,
		&[href*=".xls"]:before,
		&[href*=".xlsx"]:before,
		&[href*=".mp4"]:before,
		&[href*=".mov"]:before  {
			@extend %icon-before;
		}
		&[href*=".pdf"]:before {
			content: "\f1c1";
		}
		&[href*=".doc"]:before,
		&[href*=".docx"]:before,
		&[href*=".txt"]:before {
			content: "\f1c2";
		}
	
		&[href*=".zip"]:before {
			content: "\f1c6";
		}
	
		&[href*=".ppt"]:before,
		&[href*=".pptx"]:before {
			content: "\f685";
		}
		&[href*=".csv"]:before,
		&[href*=".xls"]:before,
		&[href*=".xlsx"]:before {
			content: "\f0ce";
		}
	
		&[href*=".mp4"]:before,
		&[href*=".mov"]:before {
			content: "\f03d";
		}
	
		// Actions of the links go after
		&[target="_blank"]:after,
		&[href^="http"][target="_blank"]:after,
		&[href^="https"][target="_blank"]:after,
		&[href^="mailto:"]:after,
		&[href^="tel:"]:after{
			@extend %icon-after;
		}
	
		&[target="_blank"]:after,
		&[href^="http"][target="_blank"]:after,
		&[href^="https"][target="_blank"]:after{
			content: "\f14c";
		}
	
	
		&[href^="mailto:"]:after {
			content: "\f0e0";
		}
	
		&[href^="tel:"]:after {
			content: "\f095";
		}
	
		&[href^="fax:"]:after {
			content: "\f1ac";
		}
 
	
	
	}



}

%link-inherit{
	color:inherit;
	text-decoration-color:currentColor;
	
	&:visited, &:hover,&:focus { 
		color:inherit;
		text-decoration-color:currentColor;
	}
}

%link-dark{
	@if($usingFontAwesome){
		@extend %link-icons;
	}
	color:  var(--link-color-dark);
	text-decoration: underline;
	text-decoration-color: hsla(var(--link-color-dark-h),var(--link-color-dark-s),var(--link-color-dark-l), .25);
	text-decoration-thickness: 1px;

	&:visited{
		color:  var(--link-color-dark--visted);
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-decoration-color: inherit;
		text-decoration-style: dashed;
	}
	&:hover,&:focus  {
		color: var(--link-color-dark--hover);
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-decoration-color: inherit;
		text-decoration-style: solid;
	}
}

%link-light{
	@if($usingFontAwesome){
		@extend %link-icons;
	}
	color:var(--link-color-light);
	text-decoration-color: hsla(var(--link-color-light-h),var(--link-color-light-s),var(--link-color-light-l), .25);
	text-decoration: underline;
	&:visited{
		color: var(--link-color-light--visited);
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-decoration-color: inherit;
		text-decoration-style: dashed;
	}
	&:hover,&:focus {
		color: var(--link-color-light--hover);
		text-decoration-thickness: 2px;
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-decoration-color: inherit;
		text-decoration-style: solid;
	}
}
@if($usingFontAwesome){
	.link_icons{
		@extend %link-icons;
	}
}


[target="_blank"]:has(img):after,
[href^="http"][target="_blank"]:has(img):after,
[href^="https"][target="_blank"]:has(img):after{
		content: none !important;
	}


	


/*
Link Decorator

Links with the `.link` will auto decorate with an icon of the corresponding file type. In each brand there the the variable `$usingFontAwesome` to control if the classes should even render for the brand. $usingFontAwesome's defualt is true.| File            | Effect                                         |
| --------------- | ---------------------------------------------- |
| .pdf            | Portable Document Format                       |
| .doc            | Word Document                                  |
| .txt            | Text Document                                  |
| .ppt            | Power Point                                    |
| mailto:         | Email                                          |
| tel:            | Telephone                                      |
| fax:            | Fax                                            |
| target=`_blank` | External Link icons will appear after any link |



Markup:
<div class="p_4">
 <a class="link" href="abc.pdf">A link that is a Pdf Document</a><br/>
 <a class="link" href="abc.doc">A link that is a Word Document</a><br/>
 <a class="link" target="_blank" href="https://www.abc.ppt">An External link that is a Power Point Document</a><br/>
 <a class="link" href="tel:1-555-555-5555">1-555-555-5555</a><br/>
 <a class="link" href="mailto:test@abc.org">test@abc.org</a><br/>
 <a class="link" href="xyz.zip">A link that is a Zip Document</a><br/></div>
<div class="bg_shade-n3 p_4">
 <a class="link-light" href="abc.pdf">A link that is a Pdf Document</a><br/>
 <a class="link-light" href="abc.doc">A link that is a Word Document</a><br/>
 <a class="link-light"  target="_blank"  href="https://www.abc.ppt">An External link that is a Power Point Document</a><br/>
 <a class="link-light" href="tel:1-555-555-5555">1-555-555-5555</a><br/>
 <a class="link-light" href="mailto:test@abc.org">test@abc.org</a><br/>
 <a class="link-light" href="xyz.zip">A link that is a Zip Document</a><br/>
</div>

Styleguide Recipes.InteractiveExtras.Links
 */

 


