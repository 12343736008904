/*
Overflow

Control some basics of how content overflows.   

.overflow_hidden   - hidden
.overflow_auto    - auto
.overflow_visible    - visible
.overflow_scroll    - scroll
.overflow_clip    - clip
.overflow_ellipsis    - ellipsis
.overflow_unset   - unset

	Markup:
	<div class="br_solid br_transparent bg_hightlight-3 max-w_40 {{modifier_class}}">
		<p class="nowrap">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore quisquam vero, accusamus repellendus rerum sed dolorem quidem harum, neque odit dolor minima, quia et recusandae voluptatibus similique! Earum, unde laborum.</p>
	</div>

	MarkupWrapperClasses:
	grid template-x_30 texture_light overflow_hidden


Weight: 100

Styleguide Layouts.Display.overflow

*/

.overflow_hidden {
	overflow: hidden;
}

.overflow_visible {
	overflow: visible;
}

.overflow_auto {
	overflow: auto;
}

.overflow_scroll {
	overflow-x: scroll;
	overflow-y: scroll;
}

.overflow_scroll-x {
	overflow-x: scroll;
	overflow-y: hidden;
}
.overflow_scroll-y {
	overflow-x: hidden;
	overflow-y: scroll;
}


.overflow_clip {
	@extend .overflow_hidden;
	text-overflow: clip;
}

.overflow_ellipsis {
	@extend .overflow_hidden;
	text-overflow: ellipsis;
}

.overflow_unset {
	overflow: unset;
}
